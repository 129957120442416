var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        visible: _vm.dialogVisible,
        width: "800px",
        title: _vm.editData.id ? "修改产品库信息" : "新增产品库信息",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "中文品名", prop: "nameCH" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "672px" },
                    model: {
                      value: _vm.form.nameCH,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nameCH", $$v)
                      },
                      expression: "form.nameCH",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "英文品名", prop: "nameEN" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "672px" },
                    model: {
                      value: _vm.form.nameEN,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nameEN", $$v)
                      },
                      expression: "form.nameEN",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用途", prop: "used" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    model: {
                      value: _vm.form.used,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "used", $$v)
                      },
                      expression: "form.used",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "材质", prop: "material" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    model: {
                      value: _vm.form.material,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "material", $$v)
                      },
                      expression: "form.material",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "美国税号", prop: "hsCode" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "290px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.hsCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hsCode", $$v)
                      },
                      expression: "form.hsCode",
                    },
                  }),
                  _c("i", {
                    staticClass: "el-icon-search icon",
                    on: { click: _vm.search },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "基础税率", prop: "baseRate" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.baseRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "baseRate", $$v)
                      },
                      expression: "form.baseRate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "加征税率", prop: "addRate" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.addRate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "addRate", $$v)
                      },
                      expression: "form.addRate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "批次号", prop: "batchNo" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "320px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.batchNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "batchNo", $$v)
                      },
                      expression: "form.batchNo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "672px" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确定" + _vm._s(_vm.editData.id ? "修改" : "新增"))]
          ),
        ]
      ),
      _c("TaxDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2 },
        on: { ok: _vm.ok2, cancel: _vm.cancel2 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }